<template>
  <div>
    <CRow>
      <CCol lg="8">
        <h2>Districts</h2>
      </CCol>
      <CCol lg="4"> </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "Districts",
  components: {},
};
</script>
